import React from "react";

const Billing = () => {
  const connectSquare = () => {
    const clientId = "sq0idp-NdMtq_w0ch7pmjw1tM3tiw";
    const redirectUri =
      "https://us-central1-symbri-production.cloudfunctions.net/handleSquareOauth2";
    const squareUrl = `https://connect.squareup.com/oauth2/authorize?client_id=${clientId}&response_type=code&scope=MERCHANT_PROFILE_READ&redirect_uri=${redirectUri}`;

    window.location.href = squareUrl;
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <button
        onClick={connectSquare}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          border: "1px solid black",
          borderRadius: "5px",
          backgroundColor: "#4F46E5",
          color: "white",
        }}
      >
        Connect Square Account
      </button>
      <div className="mt-2">
        (this currently just lets you connect via oauth2 and calls the oauth2
        api to get the token, no further functionality yet. Work in progress.{" "}
        <br />
        Since the db is not completely secure yet please do not connect any prod
        square accounts to this. <br /> This does not need QA yet, and this will
        be hidden when dev is pushed to prod.)
      </div>
    </div>
  );
};

export default Billing;
