import React, { useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import SelectEmployee from "../Tasks/SelectEmployee";
import MultiSelect from "../Subscriptions/MultiSelect";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import ViewReport from "./ViewReport";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function Audit(props) {
  const [fromDate, setFromDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [toDate, setToDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [viewReportData, setViewReportData] = useState();
  const [viewReport, setViewReport] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const db = firebase.firestore();

  const handlePictureClick = (card) => {
    console.log("Making picture bigger for:", card.name);
  };

  const fetchReports = async () => {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      const res = await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/handleAudit",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
          }),
        }
      );

      const json = await res.json();

      console.log(json.reports);

      if (json?.reports) {
        setAllReports(json.reports);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEmployees = async () => {
    const employeesQuery = await db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Workers")
      .get();

    let employees = [];
    employeesQuery.forEach((doc) => {
      const data = doc.data();
      console.log(data);
      employees.push(data);
    });

    setSelectedEmployee(employees.map((a) => a.name));
    setEmployees(employees);
  };

  useEffect(() => {
    fetchReports();
    fetchEmployees();
  }, []);

  const handleView = (report) => {
    setViewReportData(report);
    console.log(report);
    setViewReport(true);
  };

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold text-gray-900 mb-4">
        Employee Audit
      </h1>
      <div className="flex flex-wrap items-end gap-4 mb-4">
        <div>
          <label className="block mb-1">From</label>
          <input
            type="date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1"
          />
        </div>
        <div>
          <label className="block mb-1">To</label>
          <input
            type="date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            className="border border-gray-300 rounded px-2 py-1"
          />
        </div>
        <div>
          <label className="block mb-1">Employee</label>
          <MultiSelect
            setValue={setSelectedEmployee}
            options={employees.map((a) => a.name)}
            value={selectedEmployee}
            title="Choose Employees"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {allReports
          .filter((report) =>
            selectedEmployee.some((a) => a === report.reportEmployee.name)
          )
          .filter((report) => {
            // Convert Firestore timestamp to dayjs object
            const reportDate = dayjs
              .unix(report.reportDate._seconds)
              .startOf("day");
            const from = dayjs(fromDate).startOf("day");
            const to = dayjs(toDate).endOf("day"); // Include the full day for toDate

            return (
              reportDate.isSameOrAfter(from) && reportDate.isSameOrBefore(to)
            );
          })
          .map((report) => (
            <div
              key={report.reportId}
              className="relative border border-gray-300 rounded p-4 min-h-[150px]"
            >
              <div>
                <strong>
                  {report.reportName.replace(
                    /\w+/g,
                    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
                  )}
                </strong>
                <br />
                <strong>
                  {report.customerData.customerFirstName.replace(
                    /\w+/g,
                    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
                  )}{" "}
                  {report.customerData.customerLastName.replace(
                    /\w+/g,
                    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
                  )}
                </strong>
                <br />
                <span>
                  {report.reportEmployee.name.replace(
                    /\w+/g,
                    (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()
                  )}
                </span>
              </div>
              <div
                className="absolute top-4 right-4 text-blue-600 underline cursor-pointer"
                onClick={() => handleView(report)}
              >
                View Report
              </div>
              <div
                onClick={() => handlePictureClick(report)}
                className="w-full h-80 bg-gray-200 my-4 flex items-center justify-center cursor-pointer"
              >
                <img
                  className="w-full h-full object-cover rounded"
                  src={
                    report.reportObjects.find((a) => a.type === "picsVids")
                      ?.value[0]
                  }
                  alt="employee"
                  onError={(e) => {
                    const images = report.reportObjects.find(
                      (a) => a.type === "picsVids"
                    )?.value;
                    if (images && images.length > 1) {
                      const currentSrc = e.target.src;
                      const currentIndex = images.indexOf(currentSrc);
                      const nextImage = images[currentIndex + 1];
                      if (nextImage) {
                        e.target.src = nextImage;
                      }
                    }
                  }}
                />
              </div>
              <div className="flex gap-4">
                <div>
                  Chemistry{" "}
                  <span
                    className={`inline-block w-2 h-2 rounded-full ${
                      true ? "bg-red-500" : "bg-gray-400"
                    }`}
                  />
                </div>
                <div>
                  Time{" "}
                  <span
                    className={`inline-block w-2 h-2 rounded-full ${
                      false ? "bg-red-500" : "bg-gray-400"
                    }`}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      <ViewReport
        viewDetailsData={viewReportData}
        open={viewReport}
        setOpen={setViewReport}
      />
    </div>
  );
}
