import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";
import Select from "./Select";

export default function EditChemical(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const [newChemicalName, setNewChemicalName] = useState("");
  const [newChemicalDose, setNewChemicalDose] = useState("");
  const [newChemicalPricePerDose, setNewChemicalPricePerDose] = useState("");
  const [selectedChemicalTag, setSelectedChemicalTag] = useState("None");
  const [selectedChemicalPreferred, setSelectedChemicalPreferred] =
    useState("No");
  const selectedChemical = props.selectedChemical;

  useEffect(() => {
    setOpen(props.open);
    if (selectedChemical) {
      setNewChemicalName(selectedChemical.chemicalName);
      setNewChemicalDose(selectedChemical.chemicalDose);
      setNewChemicalPricePerDose(selectedChemical.chemicalPricePerDose);
      setSelectedChemicalTag(selectedChemical.chemicalTag);
      setSelectedChemicalPreferred(
        selectedChemical.chemicalPreferred ? "Yes" : "No"
      );
    }
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    setNewChemicalDose("");
    setNewChemicalName("");
    setNewChemicalPricePerDose("");
    setSelectedChemicalTag();
    setSelectedChemicalPreferred("No");
    props.setOpen(false);
  };

  const handleUpdateChemical = () => {
    if (newChemicalName === "" || newChemicalName.trim().length === 0) {
      toast.warn("Chemical name field is required!");
      return;
    } else if (newChemicalDose === "" || newChemicalDose.trim().length === 0) {
      toast.warn("Chemical dose field is required!");
      return;
    } else if (
      newChemicalPricePerDose === "" ||
      newChemicalPricePerDose.trim().length === 0
    ) {
      // need to also validate number format
      toast.warn("Chemical price per dose field is required!");
      return;
    }
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .doc(selectedChemical.chemicalId)
      .update({
        chemicalName: newChemicalName,
        chemicalDose: newChemicalDose,
        chemicalPricePerDose: newChemicalPricePerDose,
        chemicalTag: selectedChemicalTag,
        chemicalPreferred: selectedChemicalPreferred === "Yes",
      })
      .catch((e) => toast.warn(e))
      .then(() => {
        setNewChemicalDose("");
        setNewChemicalName("");
        setNewChemicalPricePerDose("");
        setSelectedChemicalTag();
        setSelectedChemicalPreferred("No");
        handleClose();
      });
  };

  const handleSelectPreferred = async (value) => {
    // I need to check to make sure another chemical with the same tag is not already preferred and if it is ask them if they want to do the change and that it will remove the preferred status from the other chemical

    if (!selectedChemicalTag) {
      toast.warn("Please select a tag before setting preferred status.");
    }

    if (value === "No") {
      setSelectedChemicalPreferred(value);
      return;
    }
    console.log(value);
    const chemicalSnapshot = await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .where("chemicalTag", "==", selectedChemicalTag)
      .get();

    let didFindPreferred = false;

    for (let index = 0; index < chemicalSnapshot.docs.length; index++) {
      const chemical = chemicalSnapshot.docs[index].data();
      if (chemical.chemicalPreferred) {
        didFindPreferred = true;
        const confirm = window.confirm(
          "Another chemical with the same tag is already preferred. Would you like to override the other chemical?"
        );
        if (confirm) {
          db.collection("Businesses")
            .doc(selectedBusiness)
            .collection("Chemicals")
            .doc(chemical.chemicalId)
            .update({
              chemicalPreferred: false,
            });
          setSelectedChemicalPreferred(value);
        } else {
          console.log("Did not confirm");
          setSelectedChemicalPreferred("");

          setSelectedChemicalPreferred("No");
        }
      }
    }

    if (!didFindPreferred) {
      setSelectedChemicalPreferred(value);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            Edit Chemical{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            edit your chemical.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Name{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewChemicalName(change.target.value)
                                  }
                                  value={newChemicalName}
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Dose{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  value={newChemicalDose}
                                  onChange={(change) =>
                                    setNewChemicalDose(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Price Per Dose{" "}
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setNewChemicalPricePerDose(
                                      change.target.value
                                    )
                                  }
                                  value={newChemicalPricePerDose}
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Tag
                              </label>
                              <div className="mt-1">
                                <Select
                                  value={selectedChemicalTag}
                                  setValue={setSelectedChemicalTag}
                                  options={[
                                    "None",
                                    "Chlorine Maintainer",
                                    "Shock",
                                    "Ph Increaser",
                                    "Ph Decreaser",
                                    "Salt",
                                  ]}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {" "}
                                Preferred
                              </label>
                              <div className="mt-1">
                                <Select
                                  value={selectedChemicalPreferred}
                                  setValue={handleSelectPreferred}
                                  options={["Yes", "No"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleUpdateChemical()}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
