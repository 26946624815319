/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import CheckBox from "../GlobalComponents/CheckBox";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MultiSelect(props) {
  const [selected, setSelected] = useState([]);

  const handleSelected = (values) => {
    if (!props.disabled) {
      props.setValue(values);
      setSelected(values);
    }
  };

  useEffect(() => {
    setSelected(props.value || []);
  }, [props.value]);

  return (
    <Listbox
      value={selected}
      onChange={handleSelected}
      multiple
      disabled={props.disabled}
    >
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button
              className={classNames(
                props.disabled
                  ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                  : "bg-white text-gray-900 cursor-default",
                "relative w-full border-gray-300 border-2 h-10 rounded-md shadow-sm pl-3 pr-10 py-2 text-left focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              )}
              disabled={props.disabled}
            >
              <span className="block truncate">{props.title}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className={classNames(
                    props.disabled ? "text-gray-400" : "text-gray-500",
                    "h-5 w-5"
                  )}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            {!props.disabled && (
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {props.options.map((option) => (
                    <Listbox.Option
                      key={option}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-indigo-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-3 pr-2 flex"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option}
                          </span>
                          <div className="ml-auto">
                            <CheckBox checked={selected} />
                          </div>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            )}
          </div>
        </>
      )}
    </Listbox>
  );
}
