import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import SelectCategory from "./SelectCategory";
import { toast } from "react-toastify";

export default function EditProduct(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productCost, setProductCost] = useState("");
  const [productSKU, setProductSKU] = useState("");
  const [isTaxable, setIsTaxable] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [productType, setProductType] = useState("Product");
  const [selectedCategory, setSelectedCategory] = useState({
    title: "None",
    color: "black",
  });

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("ProductCategories")
        .doc("Categories")
        .get()
        .then((snapshot) => {
          const data = snapshot.data();
          setAllCategories(data.categories);
        });
    }
  }, [props.open]);

  useEffect(() => {
    if (props.editProductData) {
      const {
        productName,
        productDescription,
        productPrice,
        productCost,
        productSKU,
        isTaxable,
        productType,
        category,
      } = props.editProductData;

      setProductName(productName || "");
      setProductDescription(productDescription || "");
      setProductPrice(productPrice || "");
      setProductCost(productCost || "");
      setProductSKU(productSKU || "");
      setIsTaxable(isTaxable || false);
      setProductType(productType || "Product");
      setSelectedCategory(category || { title: "None", color: "black" });
    }
  }, [props.editProductData]);

  const handleClose = () => {
    setOpen(false);
    props.setOpen(false);
  };

  const handleSaveProduct = () => {
    if (!productName || !productPrice) {
      toast.warning("Name and Price are required");
      return;
    }

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Products")
      .doc(props.editProductData.productId)
      .update({
        productName,
        productDescription,
        productPrice,
        productCost,
        productSKU,
        isTaxable,
        category: selectedCategory,
        productType,
        updatedDate: new Date().toISOString(),
      })
      .then(() => {
        toast.success("Product updated successfully!");
        handleClose();
      });
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => console.log("")}
      >
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Edit Product or Service
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Update the information below to edit your product or
                            service.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="product-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Name
                              </label>
                              <div className="mt-1">
                                <input
                                  value={productName}
                                  onChange={(change) =>
                                    setProductName(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="product-or-service"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Product or Service
                              </label>
                              <div className="mt-1">
                                <select
                                  value={productType}
                                  onChange={(change) =>
                                    setProductType(change.target.value)
                                  }
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                >
                                  <option>Product</option>
                                  <option>Service</option>
                                </select>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="category"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Category
                              </label>
                              <div className="mt-1">
                                <SelectCategory
                                  allCategories={allCategories}
                                  setSelectedCategory={setSelectedCategory}
                                  selectedCategory={selectedCategory}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="product-sku"
                                className="block text-sm font-medium text-gray-900"
                              >
                                SKU
                              </label>
                              <div className="mt-1">
                                <input
                                  value={productSKU}
                                  onChange={(change) =>
                                    setProductSKU(change.target.value)
                                  }
                                  type="text"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="product-price"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Price
                              </label>
                              <div className="mt-1">
                                <input
                                  value={productPrice}
                                  onChange={(change) =>
                                    setProductPrice(change.target.value)
                                  }
                                  type="number"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <div className="mt-2">
                                <input
                                  type="checkbox"
                                  checked={isTaxable}
                                  onChange={(change) =>
                                    setIsTaxable(change.target.checked)
                                  }
                                />
                                <span className="ml-2 text-sm text-gray-900">
                                  Taxable at standard rate
                                </span>
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="product-cost"
                                className="block text-sm font-medium text-gray-900"
                              >
                                How much it costs you
                              </label>
                              <div className="mt-1">
                                <input
                                  value={productCost}
                                  onChange={(change) =>
                                    setProductCost(change.target.value)
                                  }
                                  type="number"
                                  className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="product-description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Description
                              </label>
                              <div className="mt-1">
                                <textarea
                                  value={productDescription}
                                  onChange={(change) =>
                                    setProductDescription(change.target.value)
                                  }
                                  rows="1"
                                  className="block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm resize-none"
                                  style={{ height: "80px", overflowY: "auto" }}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveProduct}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
