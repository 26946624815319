import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Select from "./Select";
import { AiOutlinePlusSquare } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import Multiselect from "multiselect-react-dropdown";
import firebase from "firebase/compat/app";
import { generateBusinessId } from "../Tools/generateBusinessId";
import { toast } from "react-toastify";
import CheckBox from "../GlobalComponents/CheckBox";
import MultiSelect from "./MultiSelect";
import { MdMenu } from "react-icons/md";

export default function NewSubscriptionV2(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const selectedBusiness = props.selectedBusiness;
  const [subscriptionTitle, setSubscriptionTitle] = useState("");
  const [subscriptionEmployeeDescription, setSubscriptionEmployeeDescription] =
    useState("");
  const [subscriptionCustomerDescription, setSubscriptionCustomerDescription] =
    useState("");
  const [subscriptionPrice, setSubscriptionPrice] = useState(0);
  const [subscriptionChargeInterval, setSubscriptionChargeInterval] =
    useState("");
  const [subscriptionTaxEnabled, setSubscriptionTaxEnabled] = useState("");
  const [subscriptionChemicalsIncluded, setSubscriptionChemicalsIncluded] =
    useState("");
  const [subscriptionEstimatedLaborTime, setSubscriptionEstimatedLaborTime] =
    useState("");
  const [subscriptionMinPictures, setSubscriptionMinPictures] = useState(0);
  const [subscriptionServiceInterval, setSubscriptionServiceInterval] =
    useState("");
  const [
    subscriptionServiceIntervalTimesPerWeek,
    setSubscriptionServiceIntervalTimesPerWeek,
  ] = useState("");

  const [selectedSubscriptionTests, setSelectedSubscriptionTests] = useState(
    []
  );
  const [
    selectedStopCustomerReportContent,
    setSelectedStopCustomerReportContent,
  ] = useState([]);

  const [formattedTests, setFormattedTests] = useState([]);
  const [formattedChemicals, setFormattedChemicals] = useState([]);
  const [subscriptionTasks, setSubscriptionTasks] = useState([]);
  const [reload, setReload] = useState(false);
  const [newTaskName, setNewTaskName] = useState("");
  const [allChemicals, setAllChemicals] = useState([]);
  const [selectedChemicals, setSelectedChemicals] = useState([]);
  const [getReportContent, setGetReportContent] = useState(0);
  const [customerReportContent, setCustomerReportContent] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [chemicalTests, setChemicalTests] = useState([]);
  const [dragId, setDragId] = useState("");
  const [dragOverItem, setDragOverItem] = useState(null);

  useEffect(() => {
    setOpen(props.open);
    if (props.open) {
      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Chemicals")
        .get()
        .then((snapshot) => {
          let chems = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();

            chems.push({
              name: doc.chemicalName,
              chemicalId: doc.chemicalId,
              id: generateBusinessId(),
              value: doc.chemicalName,
              type: "stopTests",
              visible: true,
            });
          }
          setAllChemicals(chems);
        });

      db.collection("Businesses")
        .doc(selectedBusiness)
        .collection("Measurements")
        .get()
        .then((snapshot) => {
          let chemTests = [];
          for (let index = 0; index < snapshot.docs.length; index++) {
            const doc = snapshot.docs[index].data();
            chemTests.push({
              name: doc.measurementName,
              id: generateBusinessId(),
              chemicalId: doc.measurementId,
              value: doc.measurementName,
              type: "stopTests",
              visible: true,
            });
          }
          setChemicalTests(chemTests);
        });
    }
  }, [props.open]);

  useEffect(() => {
    // need to format and set to formatted state when updated

    const formattedTests = selectedSubscriptionTests.map((a) => {
      return chemicalTests.find((b) => b.name === a);
    });

    setFormattedTests(formattedTests);
  }, [selectedSubscriptionTests]);

  useEffect(() => {
    const formattedChemicals = selectedChemicals.map((a) => {
      return allChemicals.find((b) => b.name === a);
    });

    setFormattedChemicals(formattedChemicals);
  }, [selectedChemicals]);

  const handleClose = () => {
    setOpen(false);
    setSubscriptionTitle("");
    setSubscriptionEmployeeDescription("");
    setSubscriptionCustomerDescription("");
    setSubscriptionPrice(0);
    setSubscriptionTaxEnabled("");
    setSubscriptionChargeInterval("");
    setSubscriptionChemicalsIncluded("");
    setSubscriptionEstimatedLaborTime("");
    setSubscriptionMinPictures(0);
    setSubscriptionServiceInterval("");
    setSubscriptionServiceIntervalTimesPerWeek("");
    setSubscriptionTasks([]);
    setSelectedStopCustomerReportContent([]);
    setCurrentStep(0);
    setFormattedChemicals([]);
    setFormattedTests([]);
    setSelectedChemicals([]);
    setSelectedSubscriptionTests([]);

    props.setOpen(false);
  };

  const handleAddTask = () => {
    console.log(newTaskName);
    if (newTaskName === "" || newTaskName.trim().length === 0) {
      toast.warn("Task name field is required!");
      return;
    }
    let randomId = generateBusinessId();
    let currentSubTasks = subscriptionTasks;
    currentSubTasks.push({
      name: newTaskName,
      value: newTaskName,
      id: randomId,
      type: "stopTask",
      visible: true,
    });
    setSubscriptionTasks(currentSubTasks);
    setReload(!reload);
    setNewTaskName("");
    setGetReportContent(Math.random(0, 2));
  };

  const handleDeleteTask = (task) => {
    subscriptionTasks.splice(
      subscriptionTasks.findIndex((a) => a === task),
      1
    );
    setSubscriptionTasks(subscriptionTasks);
    setReload(!reload);
    setGetReportContent(Math.random(0, 2));
  };

  const onSelectChemicals = (selectedItem) => {
    let item = selectedItem;
    let currentChemicals = selectedChemicals;

    if (
      currentChemicals.findIndex(
        (a) => a.chemicalId === selectedItem.chemicalId
      ) === -1
    ) {
      currentChemicals.push(item);
      setSelectedChemicals(currentChemicals);
      setGetReportContent(Math.random(0, 2));
    } else {
      currentChemicals.splice(
        currentChemicals.findIndex((a) => a.chemicalId === item.chemicalId),
        1
      );
      setSelectedChemicals(currentChemicals);
      setGetReportContent(Math.random(0, 2));
    }
  };

  useEffect(() => {
    const c = subscriptionTasks.concat(
      selectedChemicals,
      selectedSubscriptionTests
    );
    console.log(c);
    setCustomerReportContent(c);
  }, [getReportContent]);

  const handleCreateSubscription = () => {
    if (subscriptionTitle === "" || subscriptionTitle.trim().length === 0) {
      toast.warn("Subscription name field is required!");
      return;
    } else if (subscriptionServiceInterval === "") {
      toast.warn("Subsccription service interval field is required!");
      return;
    } else if (
      subscriptionEmployeeDescription === "" ||
      subscriptionEmployeeDescription.trim().length === 0
    ) {
      toast.warn("Subscription employee description field is required!");
      return;
    } else if (
      subscriptionCustomerDescription === "" ||
      subscriptionCustomerDescription.trim().length === 0
    ) {
      toast.warn("Subscription customer description field is required!");
      return;
    } else if (
      subscriptionEstimatedLaborTime === "" ||
      subscriptionEstimatedLaborTime.trim().length === 0
    ) {
      toast.warn("Subscription estimated labor time field is required!");
      return;
    } else if (subscriptionTasks.length === 0) {
      toast.warn("Subscription tasks field is required!");
      return;
    } else if (chemicalTests.length === 0) {
      toast.warn("Subscription tests field is required!");
      return;
    } else if (selectedStopCustomerReportContent === 0) {
      toast.warn("Subscription customer report content field is required!");
      return;
    } else if (subscriptionMinPictures < 0) {
      toast.warn("Subscription minimum pictures field is required!");
      return;
    } else if (subscriptionServiceIntervalTimesPerWeek === "") {
      toast.warn("Subscription service times per week field is required!");
      return;
    } else if (subscriptionPrice <= 0) {
      toast.warn("Subscription price field is required!");
      return;
    } else if (subscriptionTaxEnabled === "") {
      toast.warn("Subscription taxes enabled field is required!");
      return;
    } else if (subscriptionChargeInterval === "") {
      toast.warn("Subscription charge interval field is required!");
      return;
    } else if (subscriptionChemicalsIncluded === "") {
      toast.warn("Subscription chemicals included field is required!");
      return;
    }

    const subscriptionId = generateBusinessId();
    const stopTemplateId = generateBusinessId();
    const subscriptionCode = Math.floor(1000 + Math.random() * 9000);
    //createRecurringStopTemplate need to creating recurring stop if true

    //create recurring stop template

    // since the selectedSubscriptionTests is just the names of the tests, we need to reformat it to include the id and type by finding the test in the chemicalTests array

    console.log(
      JSON.stringify({
        stopTests: formattedTests,
        stopChemicals: formattedChemicals,
        stopReport: selectedStopCustomerReportContent,
      })
    );

    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("RecurringStopTemplates")
      .doc(stopTemplateId)
      .set({
        stopName: subscriptionTitle,
        stopTemplateId: stopTemplateId,
        recurringSchedule: subscriptionServiceInterval,
        workerDescription: subscriptionEmployeeDescription,
        customerDescription: subscriptionCustomerDescription,
        estimatedLaborTime: subscriptionEstimatedLaborTime,
        stopTasks: subscriptionTasks,
        stopTests: formattedTests,
        stopChemicals: formattedChemicals,
        stopReport: selectedStopCustomerReportContent,
        stopMinimumPictures: subscriptionMinPictures,
        stopIntervalPerWeek: subscriptionServiceIntervalTimesPerWeek,
        stopServiceInterval: subscriptionServiceInterval,
        connectedSubscriptionId: subscriptionId,
      })
      .then(() => {
        db.collection("Businesses")
          .doc(selectedBusiness)
          .collection("Subscriptions")
          .doc(subscriptionId)
          .set({
            subscriptionTitle: subscriptionTitle,
            subscriptionDescription: subscriptionEmployeeDescription,
            subscriptionPrice: subscriptionPrice,
            subscriptionTaxEnabled: subscriptionTaxEnabled,
            subscriptionId: subscriptionId,
            subscriptionCode: subscriptionCode,
            subscriptionChargeInterval: subscriptionChargeInterval,
            subscriptionChemicalsIncluded: subscriptionChemicalsIncluded,
            connectedStopTemplateId: stopTemplateId,
          })
          .then(() => {
            toast.success("Subscription has been created!");
            setSubscriptionTitle("");
            setSubscriptionEmployeeDescription("");
            setSubscriptionCustomerDescription("");
            setSubscriptionPrice(0);
            setSubscriptionTaxEnabled("");
            setSubscriptionChargeInterval("");
            setSubscriptionChemicalsIncluded("");
            setSubscriptionEstimatedLaborTime("");
            setSubscriptionMinPictures(0);
            setSubscriptionServiceInterval("");
            setSubscriptionServiceIntervalTimesPerWeek("");
            setSubscriptionTasks([]);
            setSelectedStopCustomerReportContent([]);
            setSelectedSubscriptionTests([]);
            setCurrentStep(0);
            handleClose();
          });
      });
  };

  const handleUpdateChemicalTests = (value) => {
    let currentChemicalsTests = chemicalTests;
    currentChemicalsTests[
      currentChemicalsTests.findIndex((a) => a.id === value.id)
    ].visible =
      !currentChemicalsTests[
        currentChemicalsTests.findIndex((a) => a.id === value.id)
      ].visible;
    setChemicalTests(currentChemicalsTests);
    setGetReportContent(Math.random(0, 2));
  };

  const handleUpdateReportContent = (item) => {
    let currentReport = selectedStopCustomerReportContent;
    console.log(item);
    const index = currentReport.findIndex((a) => a.id === item.id);
    console.log(index);
    if (index === -1) {
      currentReport.push(item);
      setSelectedStopCustomerReportContent(currentReport);
      console.log(currentReport.length);
    } else {
      currentReport.splice(index, 1);
      setSelectedStopCustomerReportContent(currentReport);
      console.log(currentReport.length);
    }
    setReload(!reload);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragIndex = Number(dragId);
    const dropIndex = Number(ev.currentTarget.id);

    // need to update the selectedSubscriptionTests array with the new order

    const draggedItem = selectedSubscriptionTests[dragIndex];

    console.log(draggedItem);

    console.log(`dragIndex: ${dragIndex}`);
    console.log(`dropIndex: ${dropIndex}`);

    const newOrder = selectedSubscriptionTests.filter(
      (item, index) => index !== dragIndex
    );

    console.log(newOrder);

    newOrder.splice(dropIndex, 0, draggedItem);

    setSelectedSubscriptionTests(newOrder);

    console.log(chemicalTests);

    const reformattedTests = newOrder.map((a) => {
      return chemicalTests.find((b) => b.name === a);
    });

    console.log(reformattedTests);

    console.log("!222222");

    setFormattedTests(reformattedTests);

    setDragOverItem(null);

    setReload(!reload);
  };

  const handleChemicalsUseDrop = (ev) => {
    const dragIndex = Number(dragId);
    const dropIndex = Number(ev.currentTarget.id);

    const draggedItem = selectedChemicals[dragIndex];

    console.log(draggedItem);

    console.log(`dragIndex: ${dragIndex}`);
    console.log(`dropIndex: ${dropIndex}`);

    const newOrder = selectedChemicals.filter(
      (item, index) => index !== dragIndex
    );

    console.log(newOrder);

    newOrder.splice(dropIndex, 0, draggedItem);

    setSelectedChemicals(newOrder);

    const reformattedChemicals = newOrder.map((a) => {
      return allChemicals.find((b) => b.name === a);
    });

    console.log("!11111");

    setFormattedChemicals(reformattedChemicals);

    setDragOverItem(null);

    setReload(!reload);
  };

  const handleSubscriptionTasksDrop = (ev) => {
    const dragIndex = Number(dragId);
    const dropIndex = Number(ev.currentTarget.id);

    console.log(`dragIndex: ${dragIndex}`);
    console.log(`dropIndex: ${dropIndex}`);

    const draggedItem = subscriptionTasks[dragIndex];

    console.log(draggedItem);

    const newOrder = subscriptionTasks.filter(
      (item, index) => index !== dragIndex
    );

    console.log(newOrder);

    newOrder.splice(dropIndex, 0, draggedItem);

    setSubscriptionTasks(newOrder);

    setDragOverItem(null);

    setReload(!reload);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();

    // Get the bounding box of the current item
    const bounding = e.currentTarget.getBoundingClientRect();
    const offset = e.clientY - bounding.top; // Distance from the top

    // If the mouse pointer is in the top half, set position to 'top', otherwise 'bottom'
    if (offset < bounding.height / 2) {
      setDragOverItem({ index, position: "top" });
    } else {
      setDragOverItem({ index, position: "bottom" });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {" "}
                            New Subscription{" "}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={handleClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started by filling in the information below to
                            create your new subscription.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Subscription Name{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setSubscriptionTitle(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Subscription Price{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setSubscriptionPrice(change.target.value)
                                    }
                                    type="number"
                                    className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            )}
                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Taxes Enabled{" "}
                                </label>
                                <div className="mt-1">
                                  <Select
                                    setValue={setSubscriptionTaxEnabled}
                                    options={["Yes", "No"]}
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  How do you charge{" "}
                                </label>
                                <div className="mt-1">
                                  <Select
                                    setValue={setSubscriptionChargeInterval}
                                    options={["Per Month", "Weekly"]}
                                  />
                                </div>
                              </div>
                            )}
                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Average time to complete service?{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setSubscriptionEstimatedLaborTime(
                                        change.target.value
                                      )
                                    }
                                    type="number"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}
                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Schedule{" "}
                                </label>
                                <div className="mt-1">
                                  <Select
                                    setValue={setSubscriptionServiceInterval}
                                    options={["Weekly", "Every 2 Weeks"]}
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Times per week{" "}
                                </label>
                                <div className="mt-1">
                                  <Select
                                    setValue={
                                      setSubscriptionServiceIntervalTimesPerWeek
                                    }
                                    options={[
                                      "1",
                                      "2",
                                      "3",
                                      "4",
                                      "5",
                                      "6",
                                      "7",
                                    ]}
                                  />
                                </div>
                              </div>
                            )}
                            {currentStep === 0 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Chemicals included in price{" "}
                                </label>
                                <div className="mt-1">
                                  <Select
                                    setValue={setSubscriptionChemicalsIncluded}
                                    options={["Yes", "No"]}
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 1 && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-md font-medium text-gray-900"
                                >
                                  {" "}
                                  Employee Description{" "}
                                </label>
                                <p className="text-sm">
                                  This is the description of work your employees
                                  will see. The customer cannot see this
                                  description on their app.
                                </p>
                                <div className="mt-1">
                                  <textarea
                                    onChange={(change) =>
                                      setSubscriptionEmployeeDescription(
                                        change.target.value
                                      )
                                    }
                                    id="description"
                                    name="description"
                                    rows={4}
                                    className="block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 1 && (
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-md font-medium text-gray-900"
                                >
                                  {" "}
                                  Customer Description{" "}
                                </label>
                                <p className="text-sm">
                                  This is the description the customers will see
                                  on their app, describing what work the
                                  employee will perform. We seperated the two
                                  because you may want to show the customer
                                  something different from the employee.
                                </p>
                                <div className="mt-1">
                                  <textarea
                                    onChange={(change) =>
                                      setSubscriptionCustomerDescription(
                                        change.target.value
                                      )
                                    }
                                    id="description"
                                    name="description"
                                    rows={4}
                                    className="block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 2 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  What measurements do you want your employees
                                  to test for each visit?
                                </label>
                                <div className="mt-12">
                                  <MultiSelect
                                    setValue={setSelectedSubscriptionTests}
                                    options={chemicalTests.map((a) => a.name)}
                                    title="Add Measurements"
                                  />

                                  <div className="flex flex-col justify-center border border-gray-300 mt-4">
                                    {selectedSubscriptionTests.map(
                                      (value, index) => (
                                        <div
                                          key={value}
                                          id={selectedSubscriptionTests.indexOf(
                                            value
                                          )}
                                          onDragOver={(e) =>
                                            onDragOver(e, index)
                                          }
                                          onDragStart={handleDrag}
                                          onDrop={handleDrop}
                                          draggable
                                          className="mt-1 flex items-center cursor-pointer border-b border-gray-300 h-8"
                                        >
                                          <MdMenu className="ml-2" size={20} />
                                          <div className="ml-2">{value}</div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}

                            {currentStep === 3 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  What chemicals do you want the employees to
                                  use?
                                </label>
                                <div className="mt-12">
                                  <MultiSelect
                                    setValue={setSelectedChemicals}
                                    options={allChemicals.map((a) => a.name)}
                                    title="Add Chemicals"
                                  />
                                  <div className="flex flex-col justify-center border border-gray-300 mt-4">
                                    {selectedChemicals.map((value, index) => (
                                      <div
                                        key={value}
                                        id={selectedChemicals.indexOf(value)}
                                        onDragOver={(e) => onDragOver(e, index)}
                                        onDragStart={handleDrag}
                                        onDrop={handleChemicalsUseDrop}
                                        draggable
                                        className="mt-1 flex items-center cursor-pointer border-b border-gray-300 h-8"
                                      >
                                        <MdMenu className="ml-2" size={20} />
                                        <div className="ml-2">{value}</div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}

                            {currentStep === 4 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Minimum report photos{" "}
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setSubscriptionMinPictures(
                                        change.target.value
                                      )
                                    }
                                    type="number"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                            )}

                            {currentStep === 4 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  {" "}
                                  Create Subscription Tasks{" "}
                                </label>
                                <div className="mt-1">
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "4%",
                                      border: "2px solid rgb(175, 175, 175)",
                                      borderRadius: "5px",
                                      padding: 4,
                                    }}
                                  >
                                    <input
                                      placeholder="Enter task name..."
                                      value={newTaskName}
                                      onChange={(value) =>
                                        setNewTaskName(value.target.value)
                                      }
                                      style={{
                                        borderRadius: "5px",
                                        height: "30px",
                                        width: "76%",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    <div
                                      onClick={() => handleAddTask()}
                                      style={{
                                        marginLeft: "auto",
                                        marginTop: 2,
                                        marginRight: "2%",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                    >
                                      Add Task +
                                    </div>
                                  </div>
                                  <div
                                    className="hideScroll"
                                    style={{
                                      borderRadius: "5px",
                                      height: "200px",
                                      width: "100%",
                                      border: "2px solid rgb(175, 175, 175)",
                                      marginBottom: "4%",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {subscriptionTasks.map((task, index) => (
                                      <div
                                        draggable
                                        key={task.id}
                                        id={index}
                                        onDragOver={(e) => onDragOver(e, index)}
                                        onDragStart={handleDrag}
                                        onDrop={handleSubscriptionTasksDrop}
                                        style={{
                                          borderBottom:
                                            "1px solid rgb(175, 175, 175)",
                                          width: "100%",
                                          height: 40,
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <MdMenu className="ml-2" size={20} />
                                        <div style={{ marginLeft: "4%" }}>
                                          {task.name}
                                        </div>
                                        <div
                                          onClick={() => handleDeleteTask(task)}
                                          style={{
                                            marginLeft: "auto",
                                            marginRight: "4%",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <BiTrash size={20} />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}

                            {currentStep === 5 && (
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  When we send the customer the report to their
                                  app, what information would you like to show
                                  on the report?
                                </label>
                                <div className="mt-4">
                                  <div>
                                    <div className="mb-2">Chemicals Tested</div>
                                    <div
                                      className="hideScroll"
                                      style={{
                                        border: "1px solid black",
                                        padding: 4,
                                        maxHeight: 200,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {formattedTests.map((value) => (
                                        <div className="flex m-2">
                                          <div>{value.name}</div>
                                          <div className="ml-auto mr-4">
                                            <CheckBox
                                              onChange={() =>
                                                handleUpdateReportContent(value)
                                              }
                                              checked={selectedStopCustomerReportContent.some(
                                                (a) => a.id === value.id
                                              )}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="mt-8">
                                    <div className="mb-2">
                                      Chemicals Added To Pool
                                    </div>
                                    <div
                                      className="hideScroll"
                                      style={{
                                        border: "1px solid black",
                                        padding: 4,
                                        maxHeight: 200,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {formattedChemicals.map((value) => (
                                        <div className="flex m-2">
                                          <div>{value.name}</div>
                                          <div className="ml-auto mr-4">
                                            <CheckBox
                                              onChange={() =>
                                                handleUpdateReportContent(value)
                                              }
                                              checked={selectedStopCustomerReportContent.some(
                                                (a) => a.id === value.id
                                              )}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="mt-8">
                                    <div className="mb-2">Tasks Completed</div>
                                    <div
                                      className="hideScroll"
                                      style={{
                                        border: "1px solid black",
                                        padding: 4,
                                        maxHeight: 200,
                                        overflowY: "scroll",
                                      }}
                                    >
                                      {subscriptionTasks.map((value) => (
                                        <div className="flex m-2">
                                          <div>{value.name}</div>
                                          <div className="ml-auto mr-4">
                                            <CheckBox
                                              onChange={() =>
                                                handleUpdateReportContent(value)
                                              }
                                              checked={selectedStopCustomerReportContent.some(
                                                (a) => a.id === value.id
                                              )}
                                            />
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          currentStep === 0
                            ? handleClose(false)
                            : setCurrentStep(currentStep - 1)
                        }
                      >
                        {currentStep === 0 ? "Cancel" : "Back"}
                      </button>
                      <button
                        onClick={() =>
                          currentStep !== 5
                            ? setCurrentStep(currentStep + 1)
                            : handleCreateSubscription()
                        }
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {currentStep !== 5 ? "Next" : "Create"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
