import React, { useState, useEffect } from "react";
import NewProducts from "./NewProduct";
import Categories from "./Categories";
import ProductsButton from "./ProductsButton";
import firebase from "firebase/compat/app";
import EditProduct from "./EditProduct";
import { FiTrash } from "react-icons/fi";

export default function Products(props) {
  const [isAddingProduct, setIsAddingProduct] = useState(false);
  const [isManagingCategories, setIsManagingCategories] = useState(false);
  const [products, setProducts] = useState([]);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [editProductData, setEditProductData] = useState();

  useEffect(() => {
    const db = firebase.firestore();
    const unsubscribe = db
      .collection("Businesses")
      .doc(props.businessId)
      .collection("Products")
      .onSnapshot((snapshot) => {
        const productsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsData);
      });

    return () => unsubscribe();
  }, [props.businessId]);

  const handleEditProduct = (product) => {
    setEditProductData(product);
    setShowEditProduct(true);
  };

  const handleDeleteProduct = (product) => {
    const db = firebase.firestore();
    db.collection("Businesses")
      .doc(props.businessId)
      .collection("Products")
      .doc(product.id)
      .delete()
      .then(() => {
        console.log("Document successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 mt-8">
      <NewProducts
        selectedBusiness={props.businessId}
        open={isAddingProduct}
        setOpen={setIsAddingProduct}
      />
      <EditProduct
        selectedBusiness={props.businessId}
        open={showEditProduct}
        setOpen={setShowEditProduct}
        editProductData={editProductData}
      />
      <Categories
        open={isManagingCategories}
        setOpen={setIsManagingCategories}
        selectedBusiness={props.businessId}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            Products & Services
          </h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <ProductsButton
            setCreateProductDisplay={setIsAddingProduct}
            setCategoriesDisplay={setIsManagingCategories}
          />
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[600px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Category
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      SKU
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Cost
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {products.map((product) => (
                    <tr key={product.productId}>
                      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {product.productName}
                      </td>
                      <td
                        style={{
                          color: product.category
                            ? product.category.color
                            : "#6C7381",
                        }}
                        className="px-3 py-4 text-sm text-gray-500"
                      >
                        {product.category
                          ? product.category.title.replace(
                              /(^\w{1})|(\s+\w{1})/g,
                              (letter) => letter.toUpperCase()
                            )
                          : "None"}
                      </td>

                      <td className="px-3 py-4 text-sm text-gray-500">
                        {product.productSKU}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        {product.productType}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        ${product.productPrice}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500">
                        ${product.productCost}
                      </td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center">
                        <div
                          onClick={() => handleEditProduct(product)}
                          className="text-indigo-600 hover:text-indigo-900 ml-auto"
                        >
                          Edit
                        </div>
                        <div
                          onClick={() => handleDeleteProduct(product)}
                          className="text-indigo-600 hover:text-indigo-900 ml-4 cursor-pointer"
                        >
                          <FiTrash size={20} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
