import dayjs from "dayjs";
import { useEffect } from "react";
import firebase from "firebase/compat/app";
import { toast } from "react-toastify";

const StopDetailsCard = (props) => {
  const value = props.value;

  const unscheduleRecurringStop = (rescheduleData) => {
    // need to check if its a recurring stop or job stop
    console.log(rescheduleData);
    if (rescheduleData.type !== "stop") {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/unscheduleRecurringStop",
            {
              method: "POST",
              body: JSON.stringify({
                token: token,
                stop: rescheduleData,
                ignore: true,
                unscheduledDate: props.originalDate,
              }),
            }
          ).then(() => {
            setTimeout(() => {
              toast.success("Stop unscheduled!");
              props.setHoverDisplay(false);
              props.handleReloadEntireRoute();
            }, 1000);
          });
        });
    } else {
      firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          console.log(props.rescheduleData);
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/unscheduleOneTimeStop",
            {
              method: "POST",
              body: JSON.stringify({
                token: token,
                ignore: true,
                stop: rescheduleData,
              }),
            }
          ).then(() => {
            toast.success("Stop unscheduled!");
            props.setHoverDisplay(false);
            props.handleReloadEntireRoute();
          });
        });
    }
  };

  return value ? (
    <div
      key={value.markerId}
      style={{
        border: "1px solid black",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        width: "200px",
        backgroundColor: "white",
        marginTop: 8,
        minHeight: "164px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          fontSize: "0.8rem",
          paddingLeft: "4%",
        }}
      >
        <div>
          {value.customerName?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
            letter.toUpperCase()
          )}
        </div>
        <div>{value.address.substring(0, 22)}...</div>

        <div>
          {(value.stopNickname
            ? value.stopNickname
            : value.stopObject
            ? value.stopObject.stopNickname
            : value.name
          ).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())}
        </div>

        {!value.assigned && <div>Unassigned</div>}

        {!value.assigned && <div>Unscheduled</div>}

        {value.assigned && (
          <div>
            {value.employeeName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )}
          </div>
        )}

        {value.assigned && (
          <div style={{ display: "flex" }}>
            <div>{dayjs(props.selectedDate).format("MM/DD/YYYY")}</div>
            <div style={{ marginLeft: "4%" }}>
              {dayjs(props.selectedDate).format("ddd")}
            </div>
          </div>
        )}

        <div className="flex flex-wrap items-center">
          <div className="w-1/2">
            <div
              onClick={() => props.handleViewDetails(value)}
              style={{ color: "blue", cursor: "pointer" }}
            >
              View Details
            </div>
          </div>

          {value.type === "recStop" && (
            <div className="w-1/2">
              <div
                onClick={() => props.handleOpenReschedule(value)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Reschedule
              </div>
            </div>
          )}

          {value.assigned && (
            <div className="w-1/2">
              <div
                onClick={() => {
                  props.handleViewDay(
                    props.dayData[
                      value.stopObject
                        ? value.stopObject.contractor.id
                        : value.contractor.id
                    ],
                    value.stopObject
                      ? value.stopObject.contractor
                      : value.contractor,
                    value.completedDates
                  );
                }}
                style={{ color: "blue", cursor: "pointer" }}
              >
                View Route
              </div>
            </div>
          )}
          {value.assigned && (
            <div className="w-1/2 mt-1">
              <div
                onClick={() => unscheduleRecurringStop(value)}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Unschedule
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};

export default StopDetailsCard;
