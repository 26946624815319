import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import firebase from "firebase/compat/app";

export default function ViewDetailsRec(props) {
  const [open, setOpen] = useState(false);
  const db = firebase.firestore();
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    setOpen(props.open);
    if (props.open && props.viewDetailsData) {
      // gotta find the report sent to customer
      console.log(props.viewDetailsData);
      console.log("11111");
      db.collection("Customers")
        .doc(props.viewDetailsData.customerId)
        .collection("PoolReports")
        .where(
          "businessId",
          "==",
          props.viewDetailsData.customerData.businessIds[0]
        )
        .where(
          "stopId",
          "==",
          props.viewDetailsData.tempStopId
            ? props.viewDetailsData.tempStopId
            : props.viewDetailsData.stopId
        )
        .where("formattedReportDate", "==", props.stopDate)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            const data = querySnapshot.docs[0].data();
            setReportData(data);
          } else {
            setReportData(null);
          }
        });
    } else {
      setReportData(null);
    }
  }, [props.open, props.viewDetailsData]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />\
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto hideScroll">
                      <div className="bg-black py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          {props.viewDetailsData &&
                            props.viewDetailsData.customerData && (
                              <Dialog.Title className="text-lg font-medium text-white">
                                {props.viewDetailsData.customerData.name.replace(
                                  /(^\w{1})|(\s+\w{1})/g,
                                  (letter) => letter.toUpperCase()
                                )}
                              </Dialog.Title>
                            )}

                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-black focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose()}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          {props.viewDetailsData &&
                            props.viewDetailsData.address && (
                              <p className="text-sm text-white">
                                {props.viewDetailsData.address.split(",")[0]}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          {props.viewDetailsData &&
                            (props.viewDetailsData.type ===
                              "Reoccurring Stop" ||
                              props.viewDetailsData.type === "recStop") && (
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Subscription Title
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={props.viewDetailsData.stopNickname}
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Employee Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData
                                          .stopWorkerDescription
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Customer Description
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      disabled
                                      value={
                                        props.viewDetailsData
                                          .stopCustomerDescription
                                      }
                                      type="text"
                                      className="block w-full rounded-md border-gray-300 border-2 h-10 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="flex flex-column">
                                  {props.viewDetailsData.unscheduledDates &&
                                    !props.viewDetailsData.unscheduledDates.includes(
                                      props.stopDate
                                    ) && (
                                      <div>
                                        <div className="block text-sm font-medium text-gray-900">
                                          Date of Stop
                                        </div>
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>{props.stopDate}</div>
                                          <div className="ml-2">
                                            {dayjs(props.stopDate).format(
                                              "ddd"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  {props.viewDetailsData.unscheduledDates &&
                                    props.viewDetailsData.unscheduledDates.includes(
                                      props.stopDate
                                    ) && (
                                      <div>
                                        <div className="block text-sm font-medium text-gray-900">
                                          Original Stop Date
                                        </div>
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>{props.stopDate}</div>
                                          <div className="ml-2">
                                            {dayjs(props.stopDate).format(
                                              "ddd"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  <div className="ml-auto mr-2">
                                    <div className="block text-sm font-medium text-gray-900">
                                      Assigned Employee
                                    </div>
                                    {props.viewDetailsData.unscheduledDates &&
                                      !props.viewDetailsData.unscheduledDates.includes(
                                        props.stopDate
                                      ) && (
                                        <div className="mt-1 text-sm flex flex-row">
                                          <div>
                                            {props.viewDetailsData.contractor
                                              ? props.viewDetailsData.contractor.name.replace(
                                                  /(^\w{1})|(\s+\w{1})/g,
                                                  (letter) =>
                                                    letter.toUpperCase()
                                                )
                                              : props.viewDetailsData.employeeName.replace(
                                                  /(^\w{1})|(\s+\w{1})/g,
                                                  (letter) =>
                                                    letter.toUpperCase()
                                                )}
                                          </div>
                                        </div>
                                      )}

                                    {props.viewDetailsData.unscheduledDates &&
                                      props.viewDetailsData.unscheduledDates.includes(
                                        props.stopDate
                                      ) && (
                                        <div className="mt-1 text-sm flex flex-row">
                                          ---
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {props.viewDetailsData.completedDates &&
                                  !props.viewDetailsData.completedDates.includes(
                                    props.stopDate
                                  ) && (
                                    <div className="flex flex-column">
                                      <div
                                        onClick={() => {
                                          props.handleOpenReschedule(
                                            props.viewDetailsData
                                          );
                                        }}
                                        style={{
                                          color: "blue",
                                          borderBottom: "1px solid blue",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Reschedule Stop
                                      </div>
                                    </div>
                                  )}
                                {props.viewDetailsData.unscheduledDates &&
                                  !props.viewDetailsData.unscheduledDates.includes(
                                    props.stopDate
                                  ) && (
                                    <div>
                                      {props.viewDetailsData.completedDates &&
                                        !props.viewDetailsData.completedDates.includes(
                                          props.stopDate
                                        ) && (
                                          <div className="flex flex-column">
                                            <div
                                              onClick={() =>
                                                props.handleCompleteRecTimeStop(
                                                  props.viewDetailsData,
                                                  props.selectedDate,
                                                  props.viewDetailsData
                                                    .contractor.id
                                                )
                                              }
                                              style={{
                                                color: "blue",
                                                borderBottom: "1px solid blue",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Complete Stop
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                      {reportData && (
                        <div className="px-6">
                          <div
                            style={{
                              borderBottom: "1.4px solid #D9D9D9",
                              borderRadius: 10,
                              marginTop: -20,
                            }}
                          ></div>
                        </div>
                      )}
                      {reportData && (
                        <div
                          className="px-6"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 80,
                            marginTop: 20,
                          }}
                        >
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              marginLeft: 4,
                              marginTop: 4,
                            }}
                          >
                            {dayjs
                              .unix(reportData.reportDate?.seconds)
                              .format("DD MMM, YYYY - h:mm A")}{" "}
                            Report
                          </p>

                          <div>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginTop: 22,
                                marginLeft: 4,
                              }}
                            >
                              Pictures & Videos
                            </p>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: -5,
                                }}
                              >
                                {reportData.reportObjects
                                  ?.filter((a) => a.type === "picsVids")[0]
                                  ?.value.map((media, index) => (
                                    <div
                                      key={index}
                                      onClick={() => console.log(media)}
                                      style={{
                                        display: "inline-block",
                                        width: 120,
                                        height: 120,
                                        backgroundColor: "#fff",
                                        margin: 10,
                                        boxShadow:
                                          "0px 4px 4.65px rgba(0,0,0,0.3)",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <img
                                        src={media}
                                        alt={`media-${index}`}
                                        style={{
                                          width: 120,
                                          height: 120,
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>

                          {reportData.reportObjects?.filter(
                            (a) => a.type === "readings"
                          )[0] && (
                            <div
                              style={{
                                borderBottom: "1.4px solid #D9D9D9",
                                marginTop: "6%",
                                borderRadius: 10,
                              }}
                            ></div>
                          )}

                          <div>
                            {reportData.reportObjects?.filter(
                              (a) => a.type === "readings"
                            )[0] && (
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 600,
                                  marginTop: 32,
                                  marginLeft: 4,
                                }}
                              >
                                Readings
                              </p>
                            )}

                            {reportData.reportObjects
                              ?.filter((a) => a.type === "readings")[0]
                              ?.value.map((reading, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: 4,
                                    marginTop: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        margin: 0,
                                      }}
                                    >
                                      {reading.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        marginLeft: "auto",
                                        marginRight: "4%",
                                      }}
                                    >
                                      {reading.value}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>

                          <div
                            style={{
                              borderBottom: "1.4px solid #D9D9D9",
                              marginTop: "6%",
                              borderRadius: 10,
                            }}
                          ></div>

                          <div>
                            <p
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                marginTop: 22,
                                marginLeft: 4,
                              }}
                            >
                              Message
                            </p>

                            {reportData?.reportObjects?.find(
                              ({ type }) => type === "customerMessage"
                            )?.value && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: 4,
                                  marginTop: 10,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 500,
                                      margin: 0,
                                    }}
                                  >
                                    {
                                      reportData?.reportObjects?.find(
                                        ({ type }) => type === "customerMessage"
                                      )?.value
                                    }
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>

                          {reportData.reportObjects?.find(
                            ({ type }) => type === "chemicalsAdded"
                          ) && (
                            <div
                              style={{
                                borderBottom: "1.4px solid #D9D9D9",
                                marginTop: "4%",
                                borderRadius: 10,
                              }}
                            ></div>
                          )}

                          <div>
                            {reportData.reportObjects?.find(
                              ({ type }) => type === "chemicalsAdded"
                            ) && (
                              <p
                                style={{
                                  fontSize: 18,
                                  fontWeight: 600,
                                  marginTop: 22,
                                  marginLeft: 4,
                                }}
                              >
                                Chemicals Added
                              </p>
                            )}

                            {reportData.reportObjects
                              ?.find(({ type }) => type === "chemicalsAdded")
                              ?.value.map((chemical, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginLeft: 4,
                                    marginTop: 18,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 16,
                                        fontWeight: 500,
                                        margin: 0,
                                      }}
                                    >
                                      {chemical.chemicalData?.name}
                                    </p>
                                    <p
                                      style={{
                                        fontSize: 14,
                                        marginLeft: "auto",
                                        marginRight: "4%",
                                      }}
                                    >
                                      {chemical.value}
                                    </p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-shrink-0 justify-start px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          props.handleViewDay(
                            props.dayData[props.viewDetailsData.contractor.id],
                            props.viewDetailsData.contractor,
                            props.viewDetailsData.completedDates
                          )
                        }
                      >
                        View Route
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
