import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CalendarStrip = (props) => {
  const [currentDisplayNumber, setCurrentDisplayNumber] = useState(7);
  const [allDates, setAllDates] = useState([]);

  useEffect(() => {
    const datesArr = generateDates(new Date());
    setAllDates(datesArr);

    // Check if selectedDate is within the currently visible range
    const selectedDateIndex = datesArr.findIndex(
      (date) =>
        dayjs(date).format("MM-DD-YYYY") ===
        dayjs(props.selectedDate).format("MM-DD-YYYY")
    );

    if (
      selectedDateIndex !== -1 &&
      (selectedDateIndex < currentDisplayNumber - 7 ||
        selectedDateIndex >= currentDisplayNumber)
    ) {
      // Calculate the new display number to center around the selectedDate
      const newDisplayNumber = Math.ceil((selectedDateIndex + 1) / 7) * 7;
      setCurrentDisplayNumber(newDisplayNumber);
    }
  }, [props.selectedDate]);

  const generateDates = (date) => {
    const twoMonthsBeforeDate = dayjs(date).day(1).subtract(2, "days");
    const twoMonthsBAfterDate = dayjs(date).add(2, "month");
    let arr = [];
    for (
      let dt = new Date(twoMonthsBeforeDate);
      dt <= twoMonthsBAfterDate;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const handleSelectDate = (date) => {
    props.handleSelectDate(date);
  };

  const handleRightClick = () => {
    if (currentDisplayNumber + 7 < allDates.length) {
      setCurrentDisplayNumber(currentDisplayNumber + 7);
    } else {
      console.log("This is the end!");
    }
  };

  const handleLeftClick = () => {
    if (currentDisplayNumber - 7 >= 7) {
      setCurrentDisplayNumber(currentDisplayNumber - 7);
    } else {
      console.log("This is the beginning!");
    }
  };

  return (
    <div id="calendarStripRouteBuilderMainDiv">
      <div
        onClick={() => handleLeftClick()}
        style={{ cursor: "pointer", marginRight: 4 }}
      >
        <BsChevronLeft size={15} />
      </div>
      <div style={{ display: "flex" }}>
        {allDates
          .slice(currentDisplayNumber - 7, currentDisplayNumber)
          .map((value, index) => (
            <div className="flex flex-col items-center">
              <div className="text-sm">{dayjs(value).format("ddd")}</div>
              <div
                key={dayjs(value).format("MM-DD-YYYY")}
                onClick={() => handleSelectDate(value)}
                id={dayjs(value).format("MM-DD-YYYY")}
                style={{
                  border:
                    dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                    dayjs(value).format("MM-DD-YYYY")
                      ? "#000 1px solid"
                      : "none",
                  backgroundColor:
                    dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                    dayjs(value).format("MM-DD-YYYY")
                      ? "black"
                      : "white",
                  margin: 4,
                  marginLeft: 2.4,
                  marginRight: 2.4,
                  marginTop: 6,
                  display: "flex",
                  minWidth: "34px",
                  height: "36px",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  flexDirection: "column",
                  color:
                    dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                    dayjs(value).format("MM-DD-YYYY")
                      ? "white"
                      : "black",
                  fontSize: "0.6rem",
                  borderRadius: 8,
                }}
              >
                <div className="text-[14px]">{dayjs(value).format("DD")}</div>
              </div>

              <div
                style={{
                  width: 6,
                  height: 6,
                  backgroundColor:
                    (props.jobStopDates &&
                      props.jobStopDates.includes(
                        dayjs(value).format("MM/DD/YYYY")
                      )) ||
                    (props.daysWithStops &&
                      props.daysWithStops.includes(dayjs(value).format("dddd")))
                      ? dayjs(props.selectedDate).format("MM-DD-YYYY") ===
                        dayjs(value).format("MM-DD-YYYY")
                        ? "white"
                        : "black"
                      : "transparent",
                  borderRadius: 50,
                  marginTop: -10,
                }}
              ></div>
            </div>
          ))}
      </div>
      <div
        onClick={() => handleRightClick()}
        style={{ cursor: "pointer", marginLeft: 4 }}
      >
        <BsChevronRight size={15} />
      </div>
    </div>
  );
};

export default CalendarStrip;
