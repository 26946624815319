import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { AiOutlineEye, AiFillEye } from "react-icons/ai";
import { MdEditCalendar } from "react-icons/md";
import { getProfilePhoto } from "../../GlobalComponents/getProfilePhoto";

const EmployeeCard = (props) => {
  const db = firebase.firestore();
  const [employeeData, setEmployeeData] = useState(props.value);
  const [completedStops, setCompletedStops] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [visible, setVisible] = useState(true);
  const [profilePhoto, setProfilePhoto] = useState("");
  const [reload, setReload] = useState(false);
  const dayData = props.dayData;

  const shouldShowStop = (biWeeklyStartDate, viewedDate, stopDay) => {
    if (biWeeklyStartDate === "none" || !biWeeklyStartDate) return true;
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Helper to parse a date string (mm/dd/yyyy) into a Date object
    const parseDate = (dateString) => {
      const [month, day, year] = dateString.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    // Parse input dates
    const startDate = parseDate(biWeeklyStartDate);
    const currentDate = parseDate(viewedDate);

    // Validate stopDay and get its index
    const stopDayIndex = daysOfWeek.indexOf(stopDay);
    if (stopDayIndex === -1)
      throw new Error("Invalid day of the week provided");

    // Check if the current date is before the biWeeklyStartDate
    if (currentDate < startDate) return false;

    // Ensure the viewed date matches the stop day
    if (currentDate.getDay() !== stopDayIndex) return false;

    // Calculate the difference in weeks between startDate and currentDate
    const timeDifference = currentDate - startDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    const weeksDifference = Math.floor(daysDifference / 7);

    // Return true if the difference in weeks is even (biweekly schedule)
    return weeksDifference % 2 === 0;
  };

  useEffect(() => {
    setCompletedStops([]);
    setTotalCount(0);

    // if the stop has a .frequency with a string value of Rescheduled, it should only show up on the day it was rescheduled to which is props.selectedDate compared with the .date value
    const employeesStops = props.markersDataForProp
      .filter((a) => a.employeeUid === employeeData.uid)
      .filter((a) =>
        a.unscheduledDates
          ? !a.unscheduledDates.includes(
              dayjs(props.selectedDate).format("MM/DD/YYYY")
            )
          : true
      )
      .filter(
        (a) =>
          !a.tempStopId ||
          a.date === dayjs(props.selectedDate).format("MM/DD/YYYY")
      )
      .filter((a) =>
        shouldShowStop(
          a.biWeeklyStartDate,
          dayjs(props.selectedDate).format("MM/DD/YYYY"),
          a.day
        )
      );

    const handleProfilePhoto = async () => {
      const profilePhoto = await getProfilePhoto(employeeData);

      setProfilePhoto(profilePhoto);
    };

    handleProfilePhoto();

    let currentDayData = props.dayData;

    currentDayData[employeeData.uid] = employeesStops;

    props.setDayData(currentDayData);
    let completedStops = [];

    for (let index = 0; index < employeesStops.length; index++) {
      const stop = employeesStops[index];

      if (stop.completedDates) {
        if (
          stop.completedDates.includes(
            dayjs(props.selectedDate).format("MM/DD/YYYY")
          )
        ) {
          completedStops.push(stop);
        }
      } else if (
        stop.stopObject &&
        stop.stopObject.completedDates.includes(
          dayjs(props.selectedDate).format("MM/DD/YYYY")
        )
      ) {
        completedStops.push(stop);
      }
    }

    setCompletedStops(completedStops);
    setTotalCount(employeesStops.length);

    setReload(!reload);
  }, [props.markersDataForProp]);

  const handleDayVisibleToggle = () => {
    if (visible) {
      setVisible(false);
      props.handleToggleHiddenDay(
        dayjs(props.selectedDate).format("dddd"),
        false,
        employeeData.uid
      );
    } else {
      setVisible(true);
      props.handleToggleHiddenDay(
        dayjs(props.selectedDate).format("dddd"),
        true,
        employeeData.uid
      );
    }
  };

  useEffect(() => {
    console.log(props.hiddenEmployees);
    if (props.hiddenEmployees.length > 0) {
      if (props.hiddenEmployees.includes(employeeData.uid)) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    }
  }, [props.hiddenEmployees.length]);

  return (
    <div
      style={{
        border: "1px solid #D9D9D9",
        margin: 12,
        borderRadius: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          minHeight: 80,
        }}
      >
        <div style={{ marginLeft: "4%" }}>
          <img
            className="inline-block h-10 w-10 rounded-full"
            src={profilePhoto}
          />
        </div>

        <div style={{ marginLeft: "4%" }}>
          <div
            style={{
              display: "flex",
              fontSize: "0.8rem",
              flexDirection: "column",
              alignItems: "flex-start",
              fontWeight: "bold",
            }}
          >
            {employeeData.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            )}
          </div>

          <div
            style={{
              width: "10%",
              marginRight: 10,
              display: "flex",
              flexDirection: "row",
              fontSize: "0.8rem",
            }}
          >
            <div>{completedStops.length}</div>
            <div>/</div>
            <div>{totalCount}</div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: 100,
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            marginLeft: "auto",
            paddingRight: 20,
          }}
        >
          <div
            onClick={() =>
              props.handleViewDay(
                dayData[employeeData.uid],
                employeeData,
                completedStops
              )
            }
            style={{
              cursor: "pointer",
              fontSize: "0.68rem",
              marginLeft: "auto",
            }}
          >
            View Route
          </div>
          <div
            onClick={() => handleDayVisibleToggle()}
            style={{
              cursor: "pointer",
              marginLeft: "auto",
              marginTop: 4,
            }}
          >
            {visible ? (
              <AiFillEye color="black" size={24} />
            ) : (
              <AiOutlineEye color="black" size={24} />
            )}
          </div>
        </div>
      </div>
      {false && (
        <div
          style={{
            display: dayData.length > 0 ? "flex" : "none",
            alignItems: "center",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div style={{ marginTop: "2%", width: "100%" }}>
            {dayData.map((value, index) => (
              <div
                key={value.stopId}
                style={{
                  height: 56,
                  display: "flex",
                  alignItems: "center",
                  borderTop: "1px solid #E5E7EB",
                  borderBottom: "1px solid #E5E7EB",
                }}
              >
                <div
                  style={{
                    marginLeft: "3.6%",
                    fontSize: "0.9rem",
                    width: "8%",
                  }}
                >
                  {index + 1}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "2.8%",
                    fontSize: "0.8rem",
                  }}
                >
                  <div>
                    {value.name.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                      letter.toUpperCase()
                    )}
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "8%",
                    fontSize: "0.8rem",
                  }}
                >
                  {value.frequency.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                    letter.toUpperCase()
                  )}
                </div>
                <div
                  onClick={() => props.handleOpenReschedule(value)}
                  style={{
                    marginRight: "8%",
                    fontSize: "0.8rem",
                    cursor: "pointer",
                  }}
                >
                  <MdEditCalendar size={"1.2rem"} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeCard;
