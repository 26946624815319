import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import firebase from "firebase/compat/app";
import { CompactPicker } from "react-color";
import { toast } from "react-toastify";

export default function Categories(props) {
  const [open, setOpen] = useState(false);
  const selectedBusiness = props.selectedBusiness;
  const [categoryTitle, setCategoryTitle] = useState("");
  const db = firebase.firestore();
  const [addNewDisplay, setAddNewDisplay] = useState(false);
  const [newColor, setNewColor] = useState();
  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    setOpen(props.open);
    let listener;
    if (props.open) {
      listener = db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("ProductCategories")
        .doc("Categories")
        .onSnapshot((snapshot) => {
          const data = snapshot.data();
          setAllCategories(data.categories);
        });
    }
    return () => {
      if (listener) {
        listener();
      }
    };
  }, [props.open]);

  const handleColorPicked = (color) => {
    setNewColor(color);
  };

  const handleCreateCategory = async () => {
    if (categoryTitle.length > 0 && newColor) {
      await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("ProductCategories")
        .doc("Categories")
        .set(
          {
            categories: firebase.firestore.FieldValue.arrayUnion({
              color: newColor.hex,
              title: categoryTitle,
            }),
          },
          { merge: true }
        );

      setAddNewDisplay(false);
    } else {
      toast.warning("Title and color required!");
    }
  };

  const handleDeleteCategory = async (category) => {
    const productsToUpdate = props.products.filter(
      (p) => p.category.title === category.title
    );
    await db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("ProductCategories")
      .doc("Categories")
      .update({
        categories: firebase.firestore.FieldValue.arrayRemove(category),
      });

    for (let index = 0; index < productsToUpdate.length; index++) {
      const product = productsToUpdate[index];
      await db
        .collection("Businesses")
        .doc(selectedBusiness)
        .collection("Products")
        .doc(product.productId)
        .update({ category: { color: "black", title: "None" } });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Product Categories
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => props.setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Add and manage product categories here!
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          {!addNewDisplay && (
                            <div className="space-y-6 pt-6 pb-5">
                              <h3 className="font-medium text-gray-900">
                                All Categories
                              </h3>
                              <ul
                                role="list"
                                className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200"
                              >
                                {allCategories.map((category) => (
                                  <li
                                    key={category.title}
                                    className="flex items-center justify-between py-3"
                                  >
                                    <div className="flex items-center">
                                      <div
                                        style={{
                                          backgroundColor: category.color,
                                        }}
                                        className="h-8 w-8 rounded-full"
                                      />
                                      <p className="ml-4 text-sm font-medium text-gray-900">
                                        {category.title}
                                      </p>
                                    </div>
                                    <button
                                      onClick={() =>
                                        handleDeleteCategory(category)
                                      }
                                      type="button"
                                      className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                      Delete
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {addNewDisplay && (
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Category Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    onChange={(change) =>
                                      setCategoryTitle(change.target.value)
                                    }
                                    type="text"
                                    className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Category Color
                                </label>
                                <div className="mt-1">
                                  <CompactPicker
                                    colors={[
                                      "#F44336",
                                      "#E91E63",
                                      "#9C27B0",
                                      "#673AB7",
                                      "#3F51B5",
                                      "#2196F3",
                                      "#03A9F4",
                                      "#00BCD4",
                                      "#009688",
                                      "#4CAF50",
                                      "#8BC34A",
                                      "#CDDC39",
                                      "#FFEB3B",
                                      "#FFC107",
                                      "#FF9800",
                                      "#FF5722",
                                      "#795548",
                                      "#607D8B",
                                      "#00FF00",
                                      "#0000FF",
                                      "#FFFF00",
                                      "#FF00FF",
                                      "#00FFFF",
                                      "#FF0000",
                                      "#008000",
                                      "#0000FF",
                                      "#FFFF00",
                                      "#800080",
                                      "#808000",
                                      "#008080",
                                      "#FFA500",
                                      "#FFC0CB",
                                      "#FFD700",
                                      "#FF4500",
                                    ]}
                                    color={newColor}
                                    onChangeComplete={handleColorPicked}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() =>
                          addNewDisplay
                            ? setAddNewDisplay(false)
                            : props.setOpen(false)
                        }
                      >
                        {addNewDisplay ? "Cancel" : "Close"}
                      </button>
                      <button
                        onClick={() =>
                          addNewDisplay
                            ? handleCreateCategory()
                            : setAddNewDisplay(true)
                        }
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {addNewDisplay ? "Save" : "Create"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
