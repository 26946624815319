import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import NewChemical from "./NewChemical";
import EditChemical from "./Edit";
import { BiTrash } from "react-icons/bi";
import NewMeasurement from "./NewMeasurement";
import EditMeasurement from "./EditMeasurement";

const defaultChemicals = [
  {
    chemicalName: '3" Tabs',
    chemicalDose: "Tab",
    chemicalId: "e845a928-3efc-4cd6-9f10-3b9a9901ac99",
    chemicalPreferred: true,
    chemicalPricePerDose: "$.84",
    chemicalTag: "Chlorine Maintainer",
  },
  {
    chemicalName: "Liquid Chlorine",
    chemicalDose: "1 gallon",
    chemicalId: "2a79395e-b825-4c47-a5bc-0d3b487ba7fe",
    chemicalPreferred: true,
    chemicalPricePerDose: "$.84",
    chemicalTag: "Shock",
  },
  {
    chemicalName: "Calcium Hypochlorite",
    chemicalDose: "1 scoop",
    chemicalId: "64fb99d9-4082-4187-b50b-4d064bd2c75a",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "Shock",
  },
  {
    chemicalName: "Dichlor",
    chemicalDose: "1 scoop",
    chemicalId: "d7cd16dc-d8cf-4433-a6ac-91c5740e8a20",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "Shock",
  },
  {
    chemicalName: "Sodium Bicarbonate",
    chemicalDose: "1 scoop",
    chemicalId: "897f37ef-06f5-41d5-80c4-55920a9ee9d8",
    chemicalPreferred: true,
    chemicalPricePerDose: "$.84",
    chemicalTag: "pH Increaser",
  },
  {
    chemicalName: "Soda Ash",
    chemicalDose: "1 scoop",
    chemicalId: "06e3efed-bbd2-4c77-9cf8-2a5c40008c56",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "pH Increaser",
  },
  {
    chemicalName: "Muriatic Acid",
    chemicalDose: "1 gallon",
    chemicalId: "f22e6a8c-c8a9-4334-bcfa-326f59d10cb7",
    chemicalPreferred: true,
    chemicalPricePerDose: "$.84",
    chemicalTag: "pH Decreaser",
  },
  {
    chemicalName: "DE",
    chemicalDose: "1 scoop",
    chemicalId: "951b905a-db5c-4e39-b1a5-605737f3675e",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "filter media",
  },
  {
    chemicalName: "Salt",
    chemicalDose: "1 bag",
    chemicalId: "0e82163c-c2c6-42a7-85a5-3de913765d3d",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "salt",
  },
  {
    chemicalName: "Bromine Tabs",
    chemicalDose: "1 bag",
    chemicalId: "c4f5df68-ad8c-4648-a80b-d4ea029f2c28",
    chemicalPreferred: false,
    chemicalPricePerDose: "$.84",
    chemicalTag: "Chlorine Maintainer",
  },
];

const defaultMeasurements = [
  {
    measurementId: "dafac815-48b8-4afa-a486-b8d70fd39f91",
    measurementName: "Total Hardness",
    measurementIdealRange: "250 ppm - 500 ppm",
  },
  {
    measurementId: "c40aa3ad-3e6e-4cba-b3aa-8a6216d5639e",
    measurementName: "Total Chlorine",
    measurementIdealRange: "3 ppm - 5 ppm",
  },
  {
    measurementId: "4629db7b-5014-49ca-a959-956a7eaa0fa5",
    measurementName: "Free Chlorine",
    measurementIdealRange: "3 ppm - 5 ppm",
  },
  {
    measurementId: "038c6c85-33c8-47e1-8276-c553c6893fcf",
    measurementName: "Bromine",
    measurementIdealRange: "7.2 - 7.8",
  },
  {
    measurementId: "d9d3d52d-c77c-4255-a0aa-47dc5df2194b",
    measurementName: "pH",
    measurementIdealRange: "7.2 - 7.8",
  },
  {
    measurementId: "ae2b0f74-d695-44de-927f-87d290792aba",
    measurementName: "Alkalinity",
    measurementIdealRange: "80 - 120",
  },
  {
    measurementId: "188d108c-8aa0-4c12-b2d6-477f4299f372",
    measurementName: "Cyanuric Acid",
    measurementIdealRange: "30 ppm - 50 ppm",
  },
  {
    measurementId: "bcbe9856-905c-48fd-b8dc-1bdbdf79f426",
    measurementName: "Filter Pressure",
    measurementIdealRange: "5 psi - 25 psi",
  },
  {
    measurementId: "328fb33f-353a-4c07-9be3-ec30a2cce83d",
    measurementName: "Salt",
    measurementIdealRange: "5 psi - 25 psi",
  },
];

export default function Chemicals(props) {
  const selectedBusiness = props.businessId;
  const db = firebase.firestore();
  const [chemicals, setChemicals] = useState([]);
  const [createChemicalDisplay, setCreateChemicalDisplay] = useState(false);
  const [selectedChemical, setSelectedChemical] = useState();
  const [editChemicalDisplay, setEditChemicalDisplay] = useState(false);
  const [measurements, setMeasurements] = useState([]);
  const [measurementCreateDisplay, setMeasurementCreateDisplay] =
    useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState();
  const [editMeasurementDisplay, setEditMeasurementDisplay] = useState(false);
  const searchTerm = props.searchTerm;
  useEffect(() => {
    if (!selectedBusiness) return;

    async function checkAndAddDefaults() {
      for (const chemical of defaultChemicals) {
        const docRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Chemicals")
          .doc(chemical.chemicalId);

        const docSnap = await docRef.get();
        if (!docSnap.exists) {
          await docRef.set(chemical);
        }
      }

      for (const measurement of defaultMeasurements) {
        const docRef = db
          .collection("Businesses")
          .doc(selectedBusiness)
          .collection("Measurements")
          .doc(measurement.measurementId);

        const docSnap = await docRef.get();
        if (!docSnap.exists) {
          await docRef.set(measurement);
        }
      }
    }

    checkAndAddDefaults().catch((err) => {
      console.error("Error checking/adding defaults: ", err);
    });

    const unsubscribeChemicals = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .onSnapshot((snapshot) => {
        const chemicalsArray = snapshot.docs.map((doc) => doc.data());
        setChemicals(chemicalsArray);
      });

    const unsubscribeMeasurements = db
      .collection("Businesses")
      .doc(selectedBusiness)
      .collection("Measurements")
      .onSnapshot((snapshot) => {
        const measurementsArray = snapshot.docs.map((doc) => doc.data());
        setMeasurements(measurementsArray);
      });

    return () => {
      unsubscribeChemicals();
      unsubscribeMeasurements();
    };
  }, [db, selectedBusiness]);

  const handleEditClick = (value) => {
    setSelectedChemical(value);
    setEditChemicalDisplay(true);
  };

  const handleDeleteClick = (chemical) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Chemicals")
      .doc(chemical.chemicalId)
      .delete();
  };

  const handleDeleteMeasurement = (measurement) => {
    db.collection("Businesses")
      .doc(selectedBusiness)
      .collection("Measurements")
      .doc(measurement.measurementId)
      .delete();
  };

  const handleEditMeasurement = (measurement) => {
    setSelectedMeasurement(measurement);
    setEditMeasurementDisplay(true);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Chemicals</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setCreateChemicalDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Chemical
          </button>
        </div>
      </div>
      <div className="mt-4 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[600px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Dose
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      <div className="w-36 flex justify-center items-center">
                        Tag
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Preferred
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {chemicals
                    .filter((a) =>
                      searchTerm !== ""
                        ? a.chemicalName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        : true
                    )
                    .map((chemical) => (
                      <tr key={chemical.chemicalId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {chemical.chemicalName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalDose.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalPricePerDose.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalTag && (
                            <div className="bg-[#F1F4F8] w-36 flex justify-center items-center h-8">
                              {chemical.chemicalTag?.replace(
                                /(^\w{1})|(\s+\w{1})/g,
                                (letter) => letter.toUpperCase()
                              )}
                            </div>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {chemical.chemicalPreferred ? "Preferred" : ""}
                        </td>
                        <td
                          style={{ marginLeft: "50%" }}
                          className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center"
                        >
                          <div
                            onClick={() => handleEditClick(chemical)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          >
                            Edit
                          </div>

                          <div
                            onClick={() =>
                              defaultChemicals.find(
                                (a) => a.chemicalId === chemical.chemicalId
                              )
                                ? null
                                : handleDeleteClick(chemical)
                            }
                            className={
                              defaultChemicals.find(
                                (a) => a.chemicalId === chemical.chemicalId
                              )
                                ? "text-gray-400 ml-8"
                                : "text-indigo-600 hover:text-indigo-900 cursor-pointer ml-8"
                            }
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <NewChemical
                selectedBusiness={selectedBusiness}
                setOpen={setCreateChemicalDisplay}
                open={createChemicalDisplay}
                userPermissions={props.userPermissions}
              />
              <EditChemical
                selectedChemical={selectedChemical}
                selectedBusiness={selectedBusiness}
                setOpen={setEditChemicalDisplay}
                open={editChemicalDisplay}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:items-center mt-12">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Measurements</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            onClick={() => setMeasurementCreateDisplay(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Measurements
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg h-[600px]">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ideal Range
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    ></th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {measurements
                    .filter((a) =>
                      searchTerm !== ""
                        ? a.measurementName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        : true
                    )
                    .map((measurement) => (
                      <tr key={measurement.measurementId}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {measurement.measurementName.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {measurement.measurementIdealRange.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"></td>
                        <td
                          style={{ marginLeft: "50%" }}
                          className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex items-center"
                        >
                          <div
                            onClick={() => handleEditMeasurement(measurement)}
                            className="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                          >
                            Edit
                          </div>
                          <div
                            onClick={() =>
                              defaultMeasurements.find(
                                (a) =>
                                  a.measurementId === measurement.measurementId
                              )
                                ? null
                                : handleDeleteMeasurement(measurement)
                            }
                            className={
                              defaultMeasurements.find(
                                (a) =>
                                  a.measurementId === measurement.measurementId
                              )
                                ? "text-gray-400 ml-8"
                                : "text-indigo-600 hover:text-indigo-900 cursor-pointer ml-8"
                            }
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <NewMeasurement
                selectedBusiness={selectedBusiness}
                setOpen={setMeasurementCreateDisplay}
                open={measurementCreateDisplay}
                userPermissions={props.userPermissions}
              />
              <EditMeasurement
                selectedMeasurement={selectedMeasurement}
                selectedBusiness={selectedBusiness}
                setOpen={setEditMeasurementDisplay}
                open={editMeasurementDisplay}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
