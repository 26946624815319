import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SelectEmployee from "../Routes/RouteBuilder/SelectEmployee";
import firebase from "firebase/compat/app";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { Rings } from "react-loader-spinner";

export default function RescheduleWholeRoute(props) {
  const [open, setOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [allEmployees, setAllEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalDate, setOriginalDate] = useState();
  const moveRoutePools = props.moveRoutePools;
  const db = firebase.firestore();

  useEffect(() => {
    setOpen(props.open);
    console.log(props.rescheduleData);
    if (props.open) {
      setOriginalDate(props.originalDate);
      db.collection("Businesses")
        .doc(props.selectedBusiness)
        .collection("Workers")
        .onSnapshot((snapshots) => {
          let arrOfEmployees = [];
          snapshots.docs.forEach((value) => {
            const data = value.data();
            arrOfEmployees.push(data);
          });
          setSelectedEmployee(arrOfEmployees[0]);
          setAllEmployees(arrOfEmployees);
        });
    }
  }, [props.open]);

  const handleClose = (saved) => {
    setSelectedDate();
    if (saved) {
      props.handleReloadEntireRoute(props.rescheduleData);
    }
    props.setOpen(false);
  };

  function isDateAFutureOrToday(dateA) {
    // Parse the input dates
    const parsedDateA = new Date(dateA);

    // Get the current date and set its time to midnight (00:00:00)
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if Date A is today or in the future
    if (parsedDateA >= today) {
      return true;
    } else {
      return false;
    }
  }

  const handleMoveRoute = async () => {
    if (!selectedEmployee?.name) {
      alert("Please select an employee to move the route to.");
      return;
    }

    const result = isDateAFutureOrToday(
      dayjs(selectedDate).format("MM/DD/YYYY")
    );

    if (result) {
      setLoading(true);

      const token = await firebase.auth().currentUser.getIdToken();
      let stopsToReschedule = [];
      for (let index = 0; index < moveRoutePools.length; index++) {
        const value = moveRoutePools[index];
        if (
          !value.completedDates?.includes(
            dayjs(selectedDate).format("MM/DD/YYYY")
          )
        ) {
          if (value.type === "Reoccurring Stop" || value.type === "recStop") {
            const finalOriginalDate =
              value.originalDate && value.originalDate !== "none"
                ? value.originalDate
                : value.unscheduledDates.includes(
                    dayjs(selectedDate).format("MM/DD/YYYY")
                  )
                ? dayjs(selectedDate).format("MM/DD/YYYY")
                : originalDate;

            stopsToReschedule.push({
              token: token,
              stop: value,
              selectedDate: dayjs(selectedDate).format("MM/DD/YYYY"),
              newEmployee: selectedEmployee,
              originalDate: dayjs(finalOriginalDate).format("MM/DD/YYYY"),
              stopIsUnscheduled: false,
              ignore: true,
            });
          }
        }
      }
      await firebase
        .auth()
        .currentUser.getIdToken()
        .then((token) => {
          fetch(
            "https://us-central1-symbri-production.cloudfunctions.net/rescheduleWholeRoute",
            {
              method: "POST",
              body: JSON.stringify({
                token: token,
                stops: stopsToReschedule,
                businessId: props.selectedBusiness,
              }),
            }
          );
        });

      setTimeout(() => {
        setLoading(false);

        props.setDayViewDisplay(false);
        // need to check if route start date is already selected date and if it is call reload entire route
        if (props.routeStartDate !== dayjs(selectedDate).format("MM/DD/YYYY")) {
          console.log("11111111111111111111111111111");
          props.setRouteStartDate(dayjs(selectedDate).format("MM/DD/YYYY"));
        } else {
          console.log("222222222222222222222222222222");
          props.handleReloadEntireRoute(props.rescheduleData);
        }

        props.setOpen(false);
      }, 10000);
    } else {
      alert("Selected date cannot be in past!");
    }
  };

  const unscheduleRecurringStop = async () => {
    setLoading(true);
    const token = await firebase.auth().currentUser.getIdToken();
    for (let index = 0; index < moveRoutePools.length; index++) {
      const value = moveRoutePools[index];
      console.log({
        token: token,
        stop: value,
        ignore: true,
        unscheduledDate: originalDate,
      });
      await fetch(
        "https://us-central1-symbri-production.cloudfunctions.net/unscheduleRecurringStop",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            stop: value,
            ignore: true,
            unscheduledDate: originalDate,
          }),
        }
      );
    }

    setTimeout(() => {
      setLoading(false);
      props.setDayViewDisplay(false);
      props.handleReloadEntireRoute(props.rescheduleData);
      handleClose(true);
      toast.success("Stops unscheduled!");
    }, 10000);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => console.log()}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            Move Route
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => handleClose(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Get started moving a route with the form below!
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Employee
                              </label>
                              <div className="mt-1">
                                <SelectEmployee
                                  setSelectedEmployee={setSelectedEmployee}
                                  allEmployees={allEmployees}
                                  selectedEmployee={selectedEmployee}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="description"
                                className="block text-sm font-medium text-gray-900"
                              >
                                Move Date
                              </label>
                              <div className="mt-1">
                                <input
                                  onChange={(change) =>
                                    setSelectedDate(change.target.value)
                                  }
                                  value={selectedDate}
                                  type="date"
                                  className="block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm border-gray-300 border-2 h-10"
                                />
                              </div>
                            </div>

                            <div className="flex flex-column">
                              <div
                                onClick={unscheduleRecurringStop}
                                style={{
                                  color: "blue",
                                  borderBottom: "1px solid blue",
                                  cursor: "pointer",
                                }}
                              >
                                Unschedule Route
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleMoveRoute}
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        {loading ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>Loading </div>
                            <Rings
                              height={22}
                              width={22}
                              color="white"
                              arialLabel="loading-indicator"
                            />
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
